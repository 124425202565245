<template>
    <div class="pageMain">
      <search-head @reset="headReset" @search="headSearch">
        <el-form :inline="true" :model="searchForm">
          <el-form-item label="人员姓名">
            <el-input
              size="small"
              clearable
              v-model="searchForm.userName"
              placeholder="请输入人员姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="证件号码">
            <el-input
              size="small"
              clearable
              v-model="searchForm.userCardId"
              placeholder="请输入证件号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input
              size="small"
              clearable
              v-model="searchForm.userMobile"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="所属机构">
            <el-cascader
              size="small"
              clearable
              
              v-model="orgaIdArr"
              :options="copanyListInfo"
              popper-class="custom-cascader"
              filterable
              :props="{
                checkStrictly: true,
                expandTrigger: 'hover',
                value: 'orgaPathIds',
              }"
              @change="cascaderChange"
            ></el-cascader>
          </el-form-item> -->
          <el-form-item label="报道状态">
            <el-select
              v-model="searchForm.isReport"
              size="small"
              class="block-select"
              placeholder="请选择报道状态"
            >
              <el-option
                v-for="item in isReportList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </search-head>
      <custom-tables
        :data="tableData"
        :total="total"
        noimport="true"
        noexport="true"
        title="人员报道"
        isdel="false"
        report="false"
        isadd="fasle"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @tableReport="tableReport"
       
      >
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column
          prop="userName"
          label="人员姓名"
          align="center"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="userMobile"
          label="手机号码"
          align="center"
          width="180"
        >
        
        </el-table-column>
        <el-table-column
          prop="userCardId"
          label="证件号码"
          align="center"
          width="180"
        >
        
        </el-table-column>
        <el-table-column
          prop="userSex"
          label="人员性别"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.userSex == 0">男</span>
            <span v-if="scope.row.userSex == 1">女</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="mainId"
          label="报到人类型"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.mainId == 0">主人员</span>
            <span v-else>随行人员</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="isReport"
          label="报道状态"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.isReport == 0"
              style="color: rgb(32, 150, 71)"
              >已报道</span
            >
            <!-- <span v-if="scope.row.userAuditState == 1">已拒绝</span> -->
            <span v-if="scope.row.isReport == 1" style="color: red"
              >未报道</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="projName" align="center" label="所属机构">
        </el-table-column>
       
        <el-table-column prop="createTime" align="center" label="创建时间">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
         
            <span class="btn-icon" @click="report(scope.row)">报道</span>
           
          </template>
        </el-table-column>
       
      </custom-tables>
      
    </div>
  </template>
  
  <script>
  import basic from "../../../api/modules/basic";
  import CustomTables from "../../../components/common/CustomTables";
  import SearchHead from "../../../components/common/SearchHead";

  import { organizationCascader } from "../../../utils/tool";
  
  export default {
    name: "operator",
    components: {  SearchHead, CustomTables, },
    data() {
      return {
        item2: "",
        dialogTitle: "新增公司",
        showDialog: false,
        showDialog2: false,
        dialogTitle2: "审核",
        total: 0,
        tableData: [],
        searchForm: {
          pageNum: 1,
          pageSize: 10,
        },
        item: {},
        orgaIdArr: [],
        copanyListInfo: [],
        addType: 0,
        userid: [],
        isReportList: [
          { label: "已报到", value: 0 },
          { label: "未报到", value: 1 },
         
        ],
        countdown: 5,
        timer: null,
        isList: [],
        userIdList:[],
        mainId:''
      };
    },
    mounted() {
     
      this.getCopanyInfo();
      this.getOperInfo();
    
    },
  
    methods: {
     
     
     
      
      getCopanyInfo() {
        basic.getOrganizationTree({ orgaPid: 0 }).then((res) => {
          // 递归遍历 生成 {value, label, children}
          let data = organizationCascader(res.data);
          this.copanyListInfo = data;
        });
      },
      getOperInfo() {
      
        basic.getRoomByPage(this.searchForm).then((res) => {
          this.tableData = res.data.records;
          console.log(this.tableData, " this.tableData");
    
          this.total = res.data.total;
        });
      },
      handleCurrentChange(pageIndex) {
        this.searchForm.pageNum = pageIndex;
        this.getOperInfo();
      },
      handleSizeChange(pageSize) {
        this.searchForm.pageNum = 1;
        this.searchForm.pageSize = pageSize;
        this.getOperInfo();
      },
      headReset() {
        this.searchForm = {
          pageNum: 1,
          pageSize: 10,
        };
        this.orgaIdArr = [];
        this.getOperInfo();
      },
      headSearch() {
        this.searchForm.pageNum = 1;
        this.searchForm.pageSize = 10;
        this.getOperInfo();
      },
    
    
      report(row){
        console.log(row);
        this.userIdList=[]
        this.userIdList.push(row.userId)
        let params = {
          userIdList: this.userIdList
        }
        if( row.mainId==0){
          basic.userReport(params).then((res) => {
           if(res.code == 200 ){
            this.$message({
              message: "报道成功",
              type: "success",
            });
            this.getOperInfo();
           }else{
            this.$message({
            message: message,
            type: "error",
          });
           }
          });
        }else{
          this.$message({
            message: '随行人员不用报到',
            type: "error",
          });
        }
       
      },
      tableReport() {
        console.log(this.userid, "this.userid");
      
        if (this.userid.length > 0&&this.mainId==0) {
          basic.userReport({ userIdList: this.userid }).then((res) => {
            this.$message({
              message: "报道成功",
              type: "success",
            });
            this.getOperInfo();
          });
        } else {
          this.$message({
            message: "请先勾选报道人员或者随行人员不用报到",
            type: "error",
          });
        }
      },
     
      
      selectionChange(val) {
        this.userid = [];
        console.log(val);
        this.mainId =val.mainId
        val.map((item) => {
          this.userid.push(item.userId);
        });
      },
      
      
      cascaderChange(val) {
        let valLen = val.length;
        this.searchForm.orgaPathIds = val[valLen - 1];
      },
      editDone() {
        this.getOperInfo();
        this.showDialog = false;
      },
      showImg(url) {
        let html = `<div class="img-alert"><img src="${url}"/></div>`;
        this.$alert(html, "图片", {
          dangerouslyUseHTMLString: true,
        });
      },
    },
  };
  </script>
  
  <style scoped lang="less"></style>
  